import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroImage from "../components/Home/HeroImage";
import WhyUs from "../components/Home/WhyUs";
import Explore from "../components/Home/Explore";
import KnowMore from "../components/Home/KnowMore";
import Courses from "../components/Home/Courses";
import Testimonials from "../components/Home/Testimonials";
import Blogs from "../components/Home/Blogs";
import Partners from "../components/Home/Partners";
import { HomeQuery } from '../query';
import CourseProvider from "../providers/CourseProvider";

import HiringPartners from "../components/Home/HiringPartners"

// css
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/custom.scss"
import "../css/swiper.min.scss"
// js
// import "bootstrap/dist/js/bootstrap.min.js"
import "jquery/dist/jquery.min.js"
import "popper.js"

const IndexPage = () => {
  const data = HomeQuery();
  const { home } = data.site.siteMetadata.siteData
  const getUrl = () => {
    if (typeof window !== 'undefined')
      return {
        hrefLink: window.location.href,
        pathLink: window.location.pathname,
      }
  }
  return (
    <Layout>
      <SEO title="Home" />
      <HeroImage imageData={home} />
      <KnowMore />
      <WhyUs whyus={home.whyUs} />
      <Explore explore={home.explore} />
      <CourseProvider>
        <Courses
          courses={data.courses.edges}
          urlLink={getUrl()}
          category={data.category.edges}
        />
      </CourseProvider>
      <Testimonials testimonials={home.testimonials} />
      <Blogs blogs={home.blogs} />
      <Partners partners={home.partners} />
      <HiringPartners hiringPartner={home.hiringPartner} />
    </Layout>
  )
}

export default IndexPage;
