import React from 'react';
import PropTypes from 'prop-types';

import CommonCourses from '../Courses/Courses';

const Courses = ({ courses, urlLink, category }) => {

  return <CommonCourses courses={courses} urlLink={urlLink} category={category} />

}

Courses.propTypes = {
  courses: PropTypes.array,
  urlLink: PropTypes.object,
  category: PropTypes.array,
}

export default Courses;
