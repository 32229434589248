import React from 'react';
import PropTypes from 'prop-types';

import TestimonialsSwiper from './TestimonialsSwiper';

const Testimonials = ({ testimonials }) => {
  return (
    <div>
      <div className="Testimonials">
        <div className="container">
          <h2 className="sec-title">{testimonials.title}</h2>
          <p>
            {testimonials.description1}
            <br />
            {testimonials.description2}
          </p>
          <TestimonialsSwiper user={testimonials.users} />
        </div>
      </div>
    </div>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.object,
}

export default Testimonials;
