import React from "react"
import PropTypes from "prop-types"

import blogIMG1 from "../../images/blog_img01.jpg"
import blogIMG2 from "../../images/blog_img02.jpg"

const Blogs = ({ blogs }) => {
  return (
    <div>
      <div className="Blogs">
        <div className="container">
          <h2 className="sec-title">{blogs.title}</h2>
          <p>
            {blogs.description1}
            <br />
            {blogs.description2}
          </p>
          <div className="row ">
            <div className="col-lg-4 col-md-12">
              <div className="blogCard">
                <div className="blogIMG">
                  <img src={blogIMG1} className="img-fluid" alt="" />
                </div>
                <div className="BlogInfo">
                  <h6 className="BlogBy">by. Priyesh Singhai - 30 mins ago</h6>
                  <h4 className="BlogTitle">
                    Risky Career Choices That Teenagers Often Make
                  </h4>
                  <p className="BlogDesc">
                    Lorem ipsum dolor sit amet, quo velit albucius ei. Ius no
                    sonet ignota verterem, eu sed officiis mediocrem laboramus.
                  </p>
                  <div className="tagsRow">
                    <a href="/" className="tag">
                      Tag 1
                    </a>
                    <a href="/" className="tag">
                      Tag 2
                    </a>
                    <a href="/" className="tag">
                      Tag 3
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="blogCard Card2">
                <div className="BlogInfo">
                  <h6 className="BlogBy">by. Priyesh Singhai - 30 mins ago</h6>
                  <h4 className="BlogTitle">
                    Risky Career Choices That Teenagers Often Make
                  </h4>
                  <p className="BlogDesc">
                    Lorem ipsum dolor sit amet, quo velit albucius ei. Ius no
                    sonet ignota verterem, eu sed officiis mediocrem laboramus.
                  </p>
                  <div className="tagsRow">
                    <a href="/" className="tag">
                      Tag 1
                    </a>
                    <a href="/" className="tag">
                      Tag 2
                    </a>
                    <a href="/" className="tag">
                      Tag 3
                    </a>
                  </div>
                </div>
                <div className="blogIMG">
                  <img src={blogIMG2} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Blogs.propTypes = {
  blog: PropTypes.object,
}

export default Blogs
