import React from "react"
import { Link } from "gatsby"

const Explore = ({ explore }) => {
  return (
    <div>
      <div className="Explore">
        <div className="container">
          <h2 className="sec-title">{explore.title}</h2>
          <div className="ExploreBg">
            <p>
              {explore.description1} <br />
              {explore.description2}
            </p>

            <div className="row">
              {explore.exploreImage.map(imageData => (
                <div className="col-md-4" key={imageData.id}>
                  <div className="ExploreCard">
                    <div className="ExploreImg">
                      <img
                        src={imageData.image}
                        alt={imageData.alt}
                        className="img-fluid"
                      />
                    </div>
                    <h5>{imageData.text}</h5>
                    <Link to={imageData.link} className="BtnLink">
                      Know more
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Explore
