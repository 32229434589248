import React from 'react'

function HeroImage({ children }) {
    return (
        <div className="hero-section">
            <div className="hero-content">
                <div className="container">
                    <div className="row align-items-center">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroImage
