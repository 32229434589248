import React from "react"

import HeroImages from "../HeroImages"

// images
import slide01 from "../../images/slide01.svg"

const HeroImage = ({ imageData }) => {
  return (
    <section>
      <HeroImages>
        <div className="col-md-12 col-lg-6">
          <h2>
            {imageData.title1}
            <br />
            {imageData.title2}
          </h2>
          <p>{imageData.description}</p>
          <a href="/" className="btn btn-theme btn-primary">
            {imageData.btnIndividual}
          </a>
          <a
            href="/"
            className="btn btn-theme btn-success"
            style={{ marginLeft: "10px" }}
          >
            {imageData.btnCorporate}
          </a>
        </div>
        <div className="col-md-12 col-lg-6">
          <img src={slide01} alt="slide01" className="img-fluid" />
        </div>
      </HeroImages>
    </section>
  )
}

export default HeroImage
