import React from "react"
import PropTypes from "prop-types"

import InputWithImage from "../../FormFields/InputWithImage"

import ic01 from "../../images/ic01.svg"
import ic02 from "../../images/ic02.svg"
import ic03 from "../../images/ic03.svg"
import ic04 from "../../images/ic04.svg"

const KnowMoreForm = ({ handleSubmit, handleChange }) => {
  const selectOption = [
    {
      value: "fresher",
      text: "Fresher",
    },
    {
      value: "experienced",
      text: "Experienced",
    },
  ]
  return (
    <form
      name="knowmore"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="knowmore" />
      <div className="row">
        <div className="col">
          <InputWithImage
            src={ic01}
            alt="ic01"
            type="text"
            placeholder="My name is"
            handleChange={handleChange}
            isInput={true}
            id="name"
            name="name"
          />
        </div>
        <div className="col">
          <InputWithImage
            src={ic02}
            alt="ic02"
            type="email"
            placeholder="My email is"
            handleChange={handleChange}
            isInput={true}
            id="email"
            name="email"
          />
        </div>
        <div className="col">
          <InputWithImage
            src={ic03}
            alt="ic03"
            type="number"
            placeholder="You can call me on"
            handleChange={handleChange}
            isInput={true}
            id="mobile"
            name="mobile"
          />
        </div>
        <div className="col">
          <div className="form-group">
            <InputWithImage
              src={ic04}
              alt="ic04"
              handleChange={handleChange}
              isInput={false}
              placeholder="I am a"
              onChange={handleChange}
              selectOptions={selectOption}
              smallText="We know you already are a star"
              id="type"
              name="type"
            />
          </div>
        </div>
        <div className="col">
          <button className="btn btn-theme btn-success">Get Started</button>
        </div>
      </div>
    </form>
  )
}

KnowMoreForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
}

export default KnowMoreForm
