import React, { useState } from 'react';
import { store } from 'react-notifications-component';
import KnowMoreForm from './KnowMoreForm';

const KnowMore = () => {
  const [formData, setFormData] = useState(null);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setFormData(null)
        store.addNotification({
          title: "Know More",
          message: "Data added successfully",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
      })
      .catch(error => console.log("error>>>", error));
  }

  return (
    <div>
      <div className="get-started">
        <div className="get-started-form">
          <h5 className="get-started-title">Know more about us</h5>
          <KnowMoreForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}

export default KnowMore;
