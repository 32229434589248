import React, { useEffect } from "react"
import Swiper from "swiper"
import PropTypes from "prop-types"

const timer = () => {
  new Swiper(".swip-slid", {
    speed: 400,
    loop: true,
    slidesPerView: 5,
    spaceBetween: 20,
    roundLengths: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1199: {
        slidesPerView: 4,
      },
    },
  })
}

const TestimonialsSwiper = ({ user }) => {
  useEffect(() => {
    timer()
  }, [])

  return (
    <div className="TestimonialsRow">
      <div className="swiper-container swip-slid">
        <div className="swiper-wrapper">
          {user.map(userData => (
            <div className="swiper-slide" key={userData.name}>
              <div className="TestimonialsBox">
                <div className="TestimonialsImg">
                  <img
                    src={userData.url}
                    className="img-fluid"
                    alt={userData.designation}
                  />
                </div>
                <p>“{userData.description}”</p>
                <h6 className="TestimonialsBy">{userData.name}</h6>
                <p className="designation">{userData.designation}</p>
              </div>
            </div>
          ))}
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
  )
}

TestimonialsSwiper.propTypes = {
  user: PropTypes.array,
}

export default TestimonialsSwiper
