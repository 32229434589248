import React from 'react';

const WhyUs = ({ whyus }) => {
  return (
    <div>
      <div className="why_us">
        <div className="container">
          <h2 className="sec-title">{whyus.title}</h2>
          <div className="row">
            {whyus.whyusImage.map(whyUsData => (
              <div className="col-md-4" key={whyUsData.alt}>
                <div className="why-box">
                  <div className="whyIc">
                    <img
                      src={whyUsData.img}
                      alt={whyUsData.alt}
                      className="img-fluid"
                    />
                  </div>
                  <h5>{whyUsData.text}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUs;
