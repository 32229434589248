import React from 'react'
import PropTypes from "prop-types"

const InputWithImage = ({ src, alt, type, placeholder, handleChange, isInput, selectOptions, smallText, id, name }) => {
    return (
        <div className="form-group">
            <span className="form-ic"><img src={src} className="img-fluid" alt={alt} /></span>
            <div className="input">
                {isInput ?
                    (<input
                        required
                        type={type}
                        className="form-control"
                        placeholder={placeholder}
                        onChange={handleChange}
                        id={id}
                        name={name}
                    />)
                    :
                    (<>
                        <div className="input-select">
                            <span>{placeholder}</span>
                            <select
                                required
                                className="form-control"
                                aria-describedby="textHelp"
                                onChange={handleChange}
                                onBlur={() => null}
                                id={id}
                                name={name}
                                placeholder="select type"
                                defaultValue=""
                            >
                                <option value="" disabled>Select Type</option>
                                {selectOptions.map(selectdata => (
                                    <option value={selectdata.value} key={selectdata.value}>{selectdata.text}</option>
                                ))}
                            </select>
                        </div>
                        <small id="textHelp" className="form-text">{smallText}</small>
                    </>)
                }
            </div>
        </div>
    )
}

InputWithImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    isInput: PropTypes.bool,
    selectOptions: PropTypes.array,
    smallText: PropTypes.string
}

export default InputWithImage
