import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { IoIosArrowRoundForward } from 'react-icons/io';

const HiringPartners = ({ hiringPartner }) => {
  return (
    <div>
      <div className="HiringPartners">
        <div className="container">
          <h2 className="sec-title">{hiringPartner.title}</h2>
          <h4 className="subTitle">{hiringPartner.subTitle}</h4>
          <p>{hiringPartner.description}</p>

          <div className="row">
            {hiringPartner.images.map(imageData => (
              <div className="col-sm-2" key={imageData.alt}>
                <div className="partnerLogo">
                  <img src={imageData.img} className="img-fluid" alt={imageData.alt} />
                </div>
              </div>
            ))}
          </div>
          <div className="text-center">
            <Link to="/" className="btn-effect">
              See All <IoIosArrowRoundForward className="btn-icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

HiringPartners.propTypes = {
  hiringPartner: PropTypes.object,
}

export default HiringPartners;
